/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

window.load = function() {
  jQuery('.carousel').flickity({
    groupCells: true,
    contain: true,
    wrapAround: true,
    prevNextButtons: false,
    pageDots: true
  });

  var $func = $(".functionalities"),
      $news = $(".news.slick");

    $func.slick({
      slidesToShow: 3,
      slidesToScroll: 1,
      arrows: false,
      dots: true,
      accessibility: true,
      easing: "ease-in-out",
      edgeFriction: 0.3,
      infinite: false,
      responsive: [
        {
          breakpoint: 800,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    });

    $news.slick({
      slidesToShow: 3,
      slidesToScroll: 1,
      arrows: false,
      dots: true,
      accessibility: true,
      easing: "ease-in-out",
      edgeFriction: 0.3,
      infinite: false,
      responsive: [
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    });
}

  // Check which animation prefix to use
  function whichAnimationEvent() {
    var t,
      el = document.createElement("animationCheck");

    var animations = {
      animation: "animationend",
      OAnimation: "oAnimationEnd",
      MozAnimation: "animationend",
      WebkitAnimation: "webkitAnimationEnd",
    };

    for (t in animations) {
      if (el.style[t] !== undefined) {
        return animations[t];
      }
    }
  }


  // PageableContent Widget Autoplay config
  // This widget can be seen in page "/particulares"
  var rowID = 0;
  var autoPlayID;
  var restartAutoPlayID;
  var isAutoPlaying = false;
  var autoPlaySpeed = 4000;
  var pauseAutoPlay = false;
  var pauseAutoPlayDelay = 5000;

  function clickPageableContentPaginationItem() {
    if (pauseAutoPlay) return;

    // increase row ID (already showing the first Item on load)
    rowID++;

    var lastRowID = $(".adene-pageable-content .pagination li:last-child").data(
      "row-id"
    );

    // if its the last item, start over
    if (rowID > lastRowID) {
      rowID = 0;
    }

    // get item to be clicked
    $itemToClick = $(
      '.adene-pageable-content .pagination li[data-row-id="' + rowID + '"]'
    );

    // Click the navigation item to change slide
    $itemToClick.trigger("click");
  }

  function pageableContentAutoplay() {
    var $widget = $(".adene-pageable-content");

    if ($widget.length) {
      // Timer is already running? Clear it
      if (isAutoPlaying) {
        clearInterval(autoPlayID);
        isAutoPlaying = false;
      }
      // Start timer
      if (!isAutoPlaying) {
        autoPlayID = setInterval(
          clickPageableContentPaginationItem,
          autoPlaySpeed
        );
        isAutoPlaying = true;
      }
    }
  }

  // Add content to Pageable Content Widget
  function appendContent() {
    var $selectedPage = $(".adene-pageable-content .pagination li.active"),
      $paginationItem = $(".adene-pageable-content .pagination li"),
      $lastItem = $(".adene-pageable-content .pagination li:last-child"),
      $previousContainer = $(".adene-pageable-content .previews-item"),
      $imageContainer = $(".adene-pageable-content .active .image"),
      $contentContainer = $(".adene-pageable-content .active .content"),
      activeRowID = $selectedPage.data("row-id"),
      lastRowID = $lastItem.data("row-id"),
      previousRowID =
        activeRowID > 0 && activeRowID < $paginationItem.length
          ? activeRowID - 1
          : lastRowID;

    $imageContainer.removeClass("hide");
    $contentContainer.removeClass("hide");
    $previousContainer.removeClass("hide");

    var $title = $(".buffer").find('.title[data-row-id="' + activeRowID + '"]'),
      $desc = $(".buffer").find(
        '.description[data-row-id="' + activeRowID + '"]'
      ),
      $image = $(".buffer").find('img[data-row-id="' + activeRowID + '"]');
    $previousImage = $(".buffer").find(
      'img[data-row-id="' + previousRowID + '"]'
    );

    $previousImage.clone().appendTo($previousContainer);
    $image.clone().appendTo($imageContainer);
    $title.clone().appendTo($contentContainer);
    $desc.clone().appendTo($contentContainer);
  }

  // Pageable Content Logic
  function pageableContentLogic() {
    var $widget = $(".adene-pageable-content");

    if ($widget.length > 0) {
      var $selectedPage = $(".adene-pageable-content .pagination li.active"),
        $paginationItem = $(".adene-pageable-content .pagination li"),
        $imageContainer = $(".adene-pageable-content .active .image"),
        $contentContainer = $(".adene-pageable-content .active .content"),
        $previousContainer = $(".adene-pageable-content .previews-item");
      ($lastItem = $(".adene-pageable-content .pagination li:last-child")),
        (lastRowID = $lastItem.data("row-id"));

      // Show first items
      var activeRowID = $selectedPage.data("row-id"),
        previousRowID =
          activeRowID > 0 && activeRowID < $paginationItem.length
            ? activeRowID - 1
            : lastRowID;

      var $title = $(".buffer").find(
          '.title[data-row-id="' + activeRowID + '"]'
        ),
        $desc = $(".buffer").find(
          '.description[data-row-id="' + activeRowID + '"]'
        ),
        $image = $(".buffer").find('img[data-row-id="' + activeRowID + '"]');
      $previousImage = $(".buffer").find(
        'img[data-row-id="' + previousRowID + '"]'
      );

      $previousImage.clone().appendTo($previousContainer);
      $image.clone().appendTo($imageContainer);
      $title.clone().appendTo($contentContainer);
      $desc.clone().appendTo($contentContainer);

      // When the user clicks the navigation dots
      $paginationItem.click(function () {
        // if autoplay is paused, clear timer
        if (pauseAutoPlay) {
          clearTimeout(restartAutoPlayID);
        }

        //Pause autoplay and set timer to unpause it after pauseAutoPlayDelay seconds
        pauseAutoPlay = true;
        restartAutoPlayID = setTimeout(function () {
          pauseAutoPlay = false;
        }, pauseAutoPlayDelay);

        // rowID of clicked navigation item
        activeRowID = $(this).data("row-id");
        previousRowID =
          activeRowID > 0 && activeRowID < $paginationItem.length
            ? activeRowID - 1
            : lastRowID;

        // set autoplay rowID to clicked item
        rowID = activeRowID;

        // prepare active image, previous image and content based on clicked item
        ($title = $(".buffer").find(
          '.title[data-row-id="' + activeRowID + '"]'
        )),
          ($desc = $(".buffer").find(
            '.description[data-row-id="' + activeRowID + '"]'
          )),
          ($image = $(".buffer").find(
            'img[data-row-id="' + activeRowID + '"]'
          ));
        $previousImage = $(".buffer").find(
          'img[data-row-id="' + previousRowID + '"]'
        );

        // update navigation
        $paginationItem.removeClass("active");
        $(this).addClass("active");

        // Hide the main image container
        $imageContainer.addClass("hide");

        //When animation of imageContainer ends
        //Appends the new image and hides the previews and text containers
        var animationEvent = whichAnimationEvent();
        $imageContainer.one(animationEvent, function (e) {
          //Empty the container
          $imageContainer.empty();

          // Get the new image and place it on the container
          $image.clone().appendTo($imageContainer);

          // Show the container
          $imageContainer.removeClass("hide");

          //Hide the description and "previous image" container
          $contentContainer.addClass("hide");
          $previousContainer.addClass("hide");

          // When animation of the description container ends
          // Appends the new previous image and description
          var animationEvent = whichAnimationEvent();
          $contentContainer.one(animationEvent, function (e) {
            //Empty the containers
            $contentContainer.empty();
            $previousContainer.empty();

            // Get the new "previous image" and description
            $previousImage.clone().appendTo($previousContainer);
            $title.clone().appendTo($contentContainer);
            $desc.clone().appendTo($contentContainer);

            // Show all the containers
            $imageContainer.removeClass("hide");
            $contentContainer.removeClass("hide");
            $previousContainer.removeClass("hide");
          });
        });
      });
    }
  }

  // Adds a decorative image to the row with certain classes
  // .green-detail-container    => Green decoration
  // .orange-detail-container   => Orange Decoration

  function applyDetailsToContainers() {
    var $greenContainer = $(".green-detail-container"),
      $orangeContainer = $(".orange-detail-container"),
      $yellowContainer = $(".yellow-detail-container"),
      $paintContainer = $(".paint-detail-container"),
      $toolboxContainer = $(".toolbox-detail-container"),
      $notepadContainer = $(".notepad-detail-container"),
      $keychainContainer = $(".keychain-detail-container"),
      // $orangeDetail = '<div class="orange-detail"></div>',
      $greenDetail = '<div class="green-detail"></div>',
      $yellowDetail = '<div class="yellow-detail"></div>',
      $paintDetail = '<div class="paint-detail"></div>',
      $toolboxDetail = '<div class="toolbox-detail"></div>',
      $notepadDetail = '<div class="notepad-detail"></div>',
      $keychainDetail = '<div class="keychain-detail"></div>';

    // if ($orangeContainer.length > 0) {
    //   $($orangeDetail).appendTo($orangeContainer);
    // }

    if ($greenContainer.length > 0) {
      $($greenDetail).appendTo($greenContainer);
    }

    if ($yellowContainer.length > 0) {
      $($yellowDetail).appendTo($yellowContainer);
    }

    if ($paintContainer.length > 0) {
      $($paintDetail).appendTo($paintContainer);
    }

    if ($toolboxContainer.length > 0) {
      $($toolboxDetail).appendTo($toolboxContainer);
    }

    if ($notepadContainer.length > 0) {
      $($notepadDetail).appendTo($notepadContainer);
    }

    if ($keychainContainer.length > 0) {
      $($keychainDetail).appendTo($keychainContainer);
    }
  }

  // Funcitonalities Switch Logic
  function switchLogic() {
    var $switch = $(".switch"),
      $button = $(".switch li"),
      $func = $(".functionalities"),
      $labels = $(".switch li p"),
      $btn = $(".btn_funcionalidades"),
      $toggleButton = $(".switch .adene-btn.toggle-button");

    if ($switch.length > 0) {
      $btn.attr("href", "/particulares");

      $button.click(function () {
        if (!$(this).find("p").hasClass("active")) {
          // hide / reveal "particulares" or "profissional" funcs
          $func.toggleClass("hidden");
          $func.slick("setPosition"); // recalculate slide size

          // Highlight the selected option
          $labels.removeClass("active");
          $currentLabel = $(this).find("p").first();
          $currentLabel.addClass("active");

          if ($currentLabel.text() === "Particulares") {
            $toggleButton.css("right", "50%");
            $btn.attr("href", "/particulares");
          } else if ($currentLabel.text() === "Profissionais") {
            $btn.attr("href", "/profissionais");
            $toggleButton.css("right", "0%");
          }
        }
      });
    }
  }

  // Applying Slick to Slider Widget
  function applySlickToSlider() {

    

    var carousel = jQuery(".carousell.slider");
    if (carousel.length > 0) {
      carousel.slick({
        slidesToShow:3,
        slidesToScroll: 3,
        autoplay: true,
        autoplaySpeed: 3000,
        dots: true,
        infinite: true,
        speed: 500,
        arrows: true,
      });
    }
    
    var $slider = jQuery(".slider");

    if ($slider.length > 0) {
      $slider.slick({
        autoplay: true,
        autoplaySpeed: 2000,
        dots: true,
        infinite: true,
        speed: 300,
        arrows: false,
      });
    }

    
  }

  // Apply Slick to Functionalities and News
  // function setSlickMobile() {
  //   var $func = $(".functionalities"),
  //     $news = $(".news.slick");

  //   $func.slick({
  //     slidesToShow: 3,
  //     slidesToScroll: 1,
  //     arrows: false,
  //     dots: true,
  //     accessibility: true,
  //     easing: "ease-in-out",
  //     edgeFriction: 0.3,
  //     infinite: false,
  //     responsive: [
  //       {
  //         breakpoint: 800,
  //         settings: {
  //           slidesToShow: 2,
  //           slidesToScroll: 1,
  //         },
  //       },
  //       {
  //         breakpoint: 600,
  //         settings: {
  //           slidesToShow: 1,
  //           slidesToScroll: 1,
  //         },
  //       },
  //     ],
  //   });

  //   $news.slick({
  //     slidesToShow: 3,
  //     slidesToScroll: 1,
  //     arrows: false,
  //     dots: true,
  //     accessibility: true,
  //     easing: "ease-in-out",
  //     edgeFriction: 0.3,
  //     infinite: false,
  //     responsive: [
  //       {
  //         breakpoint: 992,
  //         settings: {
  //           slidesToShow: 2,
  //           slidesToScroll: 1,
  //         },
  //       },
  //       {
  //         breakpoint: 600,
  //         settings: {
  //           slidesToShow: 1,
  //           slidesToScroll: 1,
  //         },
  //       },
  //     ],
  //   });
  // }

  // This will set the class "Scrolled" on the body when the user scrolls past a given distance
  function setScrolledHeader() {
    var scroll = $(window).scrollTop();

    if (scroll > 100) {
      $("body").addClass("scrolled");
    } else {
      $("body").removeClass("scrolled");
    }
  }

  //moves horizontal line (menus)
  function moveUnderline(el, menu) {
    if (el === null) {
      return;
    }

    var $underline = menu.find("hr"),
      left = el.offset().left,
      width = el.width();

    $underline.css({
      left: left,
      width: width,
    });
  }

  //created horizontal line below nav-primary and moves it when hovering menu items.
  function navUnderline() {
    var $menu = $(".nav-primary"),
      $active = $menu.find(".menu-item.current-menu-item").first(),
      $menuItem = $menu.find(".menu-item"),
      underlineStopped = false;

    //add underline element to menus if none exist
    if ($menu.find("hr").length === 0) {
      $menu.append('<hr class="underline" />');
    }

    //If no menu is active, underline the first one
    if ($active.length === 0) {
      $active = null;
    }

    //when page loads, moves line to active menu
    moveUnderline($active, $menu);

    // Flag for underline to stop moving after click
    $menuItem.click(function () {
      underlineStopped = true;
    });

    //moves the line below hovered menu item
    $menuItem.mouseenter(function () {
      if (!underlineStopped) {
        moveUnderline($(this), $menu);
      }
    });

    //if mouse leaves the menu, moves underline back to the active item.
    $menu.mouseleave(function () {
      if (!underlineStopped) {
        moveUnderline($active, $menu);
      }
    });
  }

  // Setting a small delay when showing the Slider Widget to avoid glitches
  // Widget will fade in.
  function showSlider() {
    $slider = $(".slider");
    setTimeout(function () {
      if ($slider.length > 0) {
        $slider.css("opacity", "1");
      }
    }, 300);
  }

  // This will tell if the element is scrolled into view.
  function isScrolledIntoView(elem) {
    var docViewTop = $(window).scrollTop(),
      docViewBottom = docViewTop + $(window).height(),
      elemTop = $(elem).offset().top,
      elemBottom = elemTop + $(elem).height();

    return elemBottom <= docViewBottom && elemTop >= docViewTop;
  }

  // Animate circular diagram widget
  function animateDiagram() {
    var $axis = $(".axis"),
      $title = $("h1"),
      nElements = $(".axis").length;

    $axis.each(function (key, value) {
      // Animate the labels
      $(this).css("transform");
      $(this).css({
        transform: "rotate(" + (key * (340 / nElements) + 30) + "deg)",
      });

      // Transform the content of the labels
      $(this).children().css("transform");
      $(this)
        .children()
        .css({
          transform: "rotate(" + (-key * (340 / nElements) - 30) + "deg)",
          opacity: "1",
        });
    });
  }

  // Diagram widget logic
  // Animate when its scrolled into view
  function diagramWidget() {
    var $diagram = $(".diagram"),
      played = false;

    if ($diagram.length > 0) {
      // play on load
      if (isScrolledIntoView(".diagram")) {
        if (!played) {
          animateDiagram();
          played = true;
        }
      }

      // on scroll, check if widget is visible and play
      $(document).scroll(function () {
        if (isScrolledIntoView(".diagram")) {
          if (!played) {
            animateDiagram();
            played = true;
          }
        }
      });
    }
  }

  // Animate numbers like a counter
  function numberCounter() {
    var $counter = $(".counter");

    if ($counter.length > 0) {
      $counter.counterUp({
        delay: 10,
        time: 800,
        formatter: function (n) {
          return n.replace(/,/g, ".");
        },
      });
    }
  }

  //Scroll to element
  function scrollToElement(ele) {
    var $element = $(ele);
    var scrollTimer = setTimeout(function () {
      $("html, body").animate(
        {
          scrollTop: $element.offset().top,
        },
        700
      );
    }, 800);
  }

  //Get URL hash and scroll to element
  function getURLHashAndScroll() {
    if (window.location.hash !== "") {
      var hash = window.location.hash;
      scrollToElement(hash);
    }
  }

  // Reveal cards on scroll for Card Grid widget
  // Page "Soluçoes de Eficiente Energética"
  function cardGridScrollReveal() {
    var $grid = $(".card-grid"),
      $cards = $(".card-item");

    if ($grid.length > 0) {
      $(window).scroll(function () {
        $.each($cards, function (key, element) {
          if ($(this).hasClass("hidden") && isScrolledIntoView($(this))) {
            $(this).removeClass("hidden");
          }
        });
      });
    }
  }

  // Open, close and submit search
  // (Search bar on page New)
  function searchBarInteraction() {
    var $searchbar = $(".search-bar"),
      $inputsArea = $(".search-inputs"),
      $close = $(".search-close"),
      $icon = $(".search-icon"),
      $submit = $(".search-submit"),
      $form = $("#search_widget"),
      $textInput = $(".search-field"),
      isOpen = false;

    if ($searchbar.length > 0) {
      // hide inputs
      $inputsArea.hide();
      $close.hide();

      // submit form with custom element (button)
      $submit.on("click", function () {
        $form.submit();
      });

      // submit form with keypress "Enter"
      $textInput.keypress(function (e) {
        if (e.which == 13) {
          $form.submit();
          return false;
        }
      });

      // close search bar
      $close.on("click", function (e) {
        e.preventDefault();

        if (isOpen) {
          $inputsArea.fadeOut();

          setTimeout(function () {
            $searchbar.addClass("closed");
            isOpen = false;
          }, 300);
        }
      });

      // open search bar
      $icon.on("click", function () {
        if (!isOpen) {
          isOpen = true;
          $searchbar.removeClass("closed");

          setTimeout(function () {
            $close.fadeIn();
            $inputsArea.fadeIn();
          }, 300);
        }
      });
    }
  }

  function shuffleArray(a) {
    var j, x, i;
    for (i = a.length - 1; i > 0; i--) {
      j = Math.floor(Math.random() * (i + 1));
      x = a[i];
      a[i] = a[j];
      a[j] = x;
    }
  }

  function parseNumber(number) {
    return Math.round(number).toLocaleString("pt");
  }

  function stats() {
    setTimeout(function () {
      if (typeof API !== "undefined") {
        var API_URL = API + "public/statistics/";
        if ($(".stats").length) {
          var DECODED_STATS = JSON.parse(STATS);
          $("#stats-zone").html("");
          shuffleArray(DECODED_STATS);
          DECODED_STATS = DECODED_STATS.slice(0, 3);
          var html = "";

          jQuery.get(API + "public/statistics/").then(function (data) {
            html += "          <table>";
            html += "            <tbody>";
            html += "              <tr>";
            DECODED_STATS.forEach(function (stat) {
              if (data[stat.value]) {
                html += "                <td>";
                html +=
                  '                  <img src="' +
                  stat.image[0] +
                  '" alt="' +
                  stat.description +
                  '" />';
                html += "                  <h2>" + stat.description + "</h2>";
                html +=
                  '                  <h1><span class="counter">' +
                  parseNumber(data[stat.value]) +
                  "</span><span>&nbsp;" +
                  (stat.symbol ? stat.symbol : "") +
                  "</span></h1>";
                html += "                </td>";
              }
            });
            html += "               </tr>";
            html += "            </tbody>";
            html += "          </table>";

            $("#stats-zone").html(html);
          });
        }
      }
    }, 100);
  }

  // Logic to close Warning bar

  function closeWarningBar() {
    var $closeButton = $(".warning-bar-close");

    $closeButton.on("click", function () {
      $(".warning-bar").addClass("closed");
    });
  }

  // Logic to open and close mobile menu
  function mobileMenu() {
    var $menu = $(".mobile"),
      $button = $(".mobile .button"),
      isOpen = false;

    if ($menu.length > 0) {
      $button.on("click", function () {
        if (!isOpen) {
          $menu.removeClass("closed");
          $menu.addClass("opened");
          isOpen = true;
        } else {
          $menu.removeClass("opened");
          $menu.addClass("closed");
          isOpen = false;
        }
      });
    }
  }

  function isLoggedIn() {
    var me = API + "private/user/me/";
    var token = window.localStorage.getItem("auth");
    if (token) {
      $.ajax({
        method: "get",
        url: me,
        headers: {
          Authorization: "JWT " + token,
        },
      }).then(function (data) {
        var user = data;
        $("#linkAderir").html("Sair");
        $("#linkAderir").attr("href", "/");
        $("#linkAderir").attr(
          "onclick",
          window.localStorage.removeItem("auth")
        );
        $("#profileTitle").html(user.user.name);
      });
    }
  }

  function getCompaniesCount() {
    var url = API + "/public/company-directory?limit=1";

    $.ajax({
      method: "get",
      url: url
    }).then(function (response) {
      if (response.count) {
        var $counter = $(".directory-company-counter");
        $counter.text(response.count);
        if ($counter.length > 0) {
          $counter.counterUp({
            delay: 10,
            time: 800
          });
        }
      }      
    });

  }

  // Clears Search input
  function clearSearch() {
    var $clearSearchButton = $(".no-results a"),
      $searchInput = $('input[name="search_query"]');

    if ($clearSearchButton.length) {
      $clearSearchButton.on("click", function (e) {
        e.preventDefault();
        $searchInput.val("");
      });
    }
  }

  function addCommas(nStr) {
      nStr += '';
      var x = nStr.split('.');
      var x1 = x[0];
      var x2 = x.length > 1 ? '.' + x[1] : '';
      var rgx = /(\d+)(\d{3})/;
      while (rgx.test(x1)) {
          x1 = x1.replace(rgx, '$1' + ' ' + '$2');
      }
      return x1 + x2;
  }

  // Apply selectize to select boxes
  function selectizeInputs() {
    setTimeout(function () {
      $.ajax({
        method: "get",
        url: 'https://api.portalcasamais.pt/api/v1/public/statistics',
        async: true,
      }).then(function (response) {
        // console.log(response);
        var savings = addCommas(Math.round(response.total_energy_certificates_savings))

        

        $('.numberStatistic-1 > .text').text(addCommas(Math.round(response.total_energy_certificates_measures)));
        $('.numberStatistic-2 > .text').text(addCommas(response.total_energy_certificates_issued));
        $('.numberStatistic-3 > .text').text(addCommas(savings));

        // response.total_energy_certificates_measures
        // response.total_energy_certificates_issued
        // response.total_energy_certificates_savings
        // Caso não exista um destes resultados, os outros também não existem
        if(!response.total_energy_certificates_measures){
          $('.first-row').remove();
        }

        $('.numberStatistic-4 > .text').text(addCommas(response.number_owners));
        $('.numberStatistic-5 > .text').text(addCommas(response.sessions));
        $('.numberStatistic-6 > .text').text(addCommas(response.number_all_companies_registrations));

        $('.numberStatistic-7 > .text').text(addCommas(response.number_improvement_measures));
        $('.numberStatistic-8 > .text').text(addCommas(response.number_electric_certificates));
        $('.numberStatistic-9 > .text').text(addCommas(response.number_company_solicitations));
        // console.log(response);
      });
    },100);

    var $selects = $(".wpcf7-form select");

    if ($selects.length > 0) {
      $selects.each(function () {
        $(this).selectize({
          create: true,
          sortField: "text",
        });
      });
    }
  }

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    common: {
      init: function () {
        navUnderline(); //Navigation menu underline
        applySlickToSlider(); // Slick slider
        //setSlickMobile(); // set slick slider for some cards on mobile
        switchLogic(); // Funcitonalities widget switch logic
        applyDetailsToContainers(); // Apply image details to containers
        pageableContentLogic(); //Pageable content widget
        showSlider(); // shows slider after load to avoid glitches
        diagramWidget(); // Animate diagram widget
        numberCounter(); //Animate numbers
        getURLHashAndScroll(); // Scroll to element using URL hash
        cardGridScrollReveal(); // Card grid reveal while scrolling
        searchBarInteraction(); // Search bar interaction (duh)
        mobileMenu(); // mobile menu logic
        selectizeInputs(); // Selectize form inputs
        clearSearch(); // Clear search input
        pageableContentAutoplay(); // Start pageable content autoplay
        isLoggedIn(); // Check if user is logged in
        stats();
        closeWarningBar();
      },
      finalize: function () {
        // JavaScript to be fired on all pages, after page specific JS is fired
      },
    },
    profissionais: {
      init: function () {
        getCompaniesCount(); // Set and Animate directory companies count        
      }
    },
    // Home page
    home: {
      init: function () {
        // JavaScript to be fired on the home page
      },
      finalize: function () {
        // JavaScript to be fired on the home page, after the init JS
      },
    },
    // About us page, note the change from about-us to about_us.
    about_us: {
      init: function () {
        // JavaScript to be fired on the about us page
      },
    },
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function (func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = funcname === undefined ? "init" : funcname;
      fire = func !== "";
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === "function";

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function () {
      // Fire common init JS
      UTIL.fire("common");

      // Fire page-specific init JS, and then finalize JS
      $.each(
        document.body.className.replace(/-/g, "_").split(/\s+/),
        function (i, classnm) {
          UTIL.fire(classnm);
          UTIL.fire(classnm, "finalize");
        }
      );

      // Fire common finalize JS
      UTIL.fire("common", "finalize");
    },
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

  //Window resize events
  window.onresize = function () {
    navUnderline(); //needed to recalcule line width on window resize
  };

  $(document).scroll(function () {
    setScrolledHeader();
  });
})(jQuery); // Fully reference jQuery after this point.
